.a-list-item__title {
	@apply max-w-70%;
	@apply pr-10 pb-4;
	@apply text-xs uppercase;
	@apply align-top;
	@apply text-regentGray;
}

.a-list-item__desc {
	@apply pb-4;
	@apply align-top;
	@apply text-fiord leading-none;
	@apply break-words;
}
