@import '../../styles/base/functions.scss';
@import '../../styles/base/variables.scss';
@import '../../styles/helpers/mixins';

@import '~vis-timeline/dist/vis-timeline-graph2d.min.css';

.o-timeline {
	.vis-timeline {
		@apply border-0;

		.vis-panel {
			box-sizing: content-box;
		}

		/** Timeline border color */
		.vis-panel.vis-bottom,
		.vis-panel.vis-center,
		.vis-panel.vis-right,
		.vis-panel.vis-top,
		.vis-foreground .vis-group,
		.vis-panel.vis-left,
		.vis-labelset .vis-label,
		.vis-time-axis .vis-grid .vis-minor {
			@apply border-gray;
		}

		/** Group labels */
		.vis-labelset .vis-label {
			@apply border-0 border-l-4 border-b-1;
			// tailwind overrides all colors,
			border-bottom-color: $border-color-blue !important;
			min-width: rem(200px);

			&__border {
				&--blue {
					@apply border-blue;
				}

				&--red {
					@apply border-red;
				}

				&--yellow {
					@apply border-yellow;
				}

				&--green {
					@apply border-green;
				}

				&--orange {
					@apply border-orange;
				}

				&--white {
					@apply border-white;
				}
			}
		}

		.vis-background {
			.vis-group:nth-child(odd) {
				background-color: rgba(#f3f6f9, 0.5);
			}
		}

		.vis-center {
			border-top: none;
		}

		.vis-current-time {
			@apply bg-red;
		}

		.vis-left {
			border-top: none;

			.vis-alert-group {
				border-left: none;
			}

			/* Vertically align timeline group content */
			.vis-label .vis-inner {
				@apply relative top-1/2 p-0;
				transform: translate3d(0, -50%, 0);
			}
		}
	}

	.a-timeline-item {
		@apply border-0;
		@apply bg-gray;
		@apply overflow-hidden;

		height: rem(30px);
		border-radius: rem(4px) !important;

		.vis-item-content {
			@apply text-sm font-bold;
			@apply text-center;

			left: 0 !important;
			width: 100% !important;
		}

		&:hover {
			cursor: pointer;
			@include coloredDropshadow($text-blue);
		}

		/* Timeline item color types */
		&--blue {
			@apply bg-blue text-white;

			&:hover {
				@include coloredDropshadow($darkBlue);
			}

			&-outline {
				@apply bg-transparent text-blue border-1 border-blue;

				&:hover {
					background-color: rgba($blue, 0.1);
				}
			}
		}

		&--red {
			@apply bg-red text-white;

			&:hover {
				@include coloredDropshadow($darkRed);
			}
		}

		&--yellow {
			@apply bg-yellow text-white;

			&:hover {
				@include coloredDropshadow($darkYellow);
			}
		}

		&--green {
			@apply bg-green text-white;

			&:hover {
				@include coloredDropshadow($darkGreen);
			}
		}

		&--orange {
			@apply bg-orange text-white;

			&:hover {
				@include coloredDropshadow($darkOrange);
			}
		}

		/* Timeline item delays & advances */
		&--dark,
		&--light {
			&::after {
				content: '';
				@apply absolute;
				@apply top-0 right-0 bottom-0 left-0;
			}
		}

		&--dark {
			&::after {
				@apply bg-black;
				@apply opacity-25;
			}
		}

		&--light {
			&::after {
				@apply bg-white;
				@apply opacity-25;
			}
		}

		&--left-cornered {
			border-top-left-radius: 0 !important;
			border-bottom-left-radius: 0 !important;
		}

		&--right-cornered {
			border-top-right-radius: 0 !important;
			border-bottom-right-radius: 0 !important;
		}
	}

	.a-timeline-alert {
		border: none;
		top: 0 !important;
		z-index: 40;

		transform: translateY(0px);

		&.vis-selected {
			background: none;
		}

		.vis-dot {
			width: rem(20px);
			height: rem(20px);

			background-image: url('../../../../assets/images/warning.svg'), linear-gradient(46deg, #c41f2a 0%, #e31f26 100%);
			background-repeat: no-repeat;
			background-size: rem(20px) rem(20px);
			background-position: center;
			border-radius: 50%;

			transform: translateX(calc(-50% + 1px)) !important;
		}
	}

	.a-timeline-passage {
		@apply border-0;

		z-index: 40;
		transform: translateY(0px);

		&.vis-selected {
			background: none;
		}

		.vis-item-content {
			@apply bg-regentGray;
			@apply rounded-full;
			@apply text-sm text-white;

			min-width: rem(20px);
			height: rem(20px);
			margin-left: 0 !important;
			padding: 0 rem(16px);

			transform: translateX(-50%);
		}

		&--actual {
			.vis-item-content {
				@apply bg-gradient-br-greenDarkGreen;
			}
		}
	}

	.vis-cluster {
		@apply bg-regentGray;
		@apply border-0 rounded-full;
		@apply text-sm text-white;

		margin-left: 0 !important;
	}

	.vis-cluster.vis-item {
		.vis-item-content {
			@apply flex items-center justify-center;
			height: rem(25px);
			width: rem(25px);
			padding: 0 !important;
		}
	}

	.vis-custom-time {
		top: rem($vis-custom-time-top-offset) !important;
		z-index: 0;
		height: calc(100% - #{rem($vis-custom-time-top-offset)}) !important;
		width: rem(1px);

		background: transparent;
		border: 2px dashed #C0C9CC;
		border-style: none dashed none none;
		pointer-events: none;

		transform: translateX(-1px);
	}

	.vis-tooltip {
		@apply bg-white;
		@apply border-0;
		@apply text-sm text-fiord;
		box-shadow: 0 rem(3px) rem(3px) rgba(0,0,0,.2);
	}
}
