@import '../../../../core/styles/base/functions.scss';

.m-dossier-info {
	@apply flex flex-col justify-between;
	@apply p-8;

	min-height: rem(288px);
}

.m-lists-wrapper {
	@apply flex flex-1 flex-row;
}

.a-list {
	@apply w-full;

	&__item {
		@apply h-8;
	}

	&__item--title {
		@apply w-1/2;
		@apply text-xs uppercase;
		@apply text-regentGray;
	}
}
