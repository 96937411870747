@import '../../styles/base/functions.scss';

.a-logo {
	@apply my-4 m-auto;
	@apply opacity-100;
}

.a-nav-icon {
	@apply z-10;
	@apply m-auto;
	@apply opacity-50;
}

.a-nav-text {
	@apply text-sm;
	@apply font-bold;
	@apply text-regentGray;
	@apply uppercase;
	@apply whitespace-no-wrap;
	@apply leading-none;

	&--small {
		@apply font-medium;
		@apply text-xs;
	}
}

.m-nav-link {
	@apply absolute;

	@apply flex flex-no-wrap items-center;
	@apply overflow-x-hidden;
	@apply h-13;
	@apply pl-0;
	@apply rounded-r-full;

	max-width: rem(64px);
	transition: .5s;

	.a-nav-text {
		@apply pr-4 pl-16;
		@apply opacity-0;
		transition: .35s;
	}
}

.o-header {
	@apply fixed top-0 left-0 bottom-0;
	@apply z-10;

	@apply w-20;

	@apply bg-white;

	&__link {
		@apply flex flex-no-wrap items-center;
		@apply h-13;
		@apply my-3 mr-2;

		.m-nav-link {
			@apply bg-transparent;
		}
	}

	&__link--active {
		.a-nav-icon {
			@apply opacity-100;

			path {
				@apply fill-white;
			}
		}

		.m-nav-link {
			@apply bg-gradient-tl-blueDarkBlue;

			.a-nav-text {
				@apply text-white;
			}
		}
	}

	.m-nav-link:hover,
	.a-nav-icon:hover+.m-nav-link {
		@apply max-w-xs;
		@apply bg-gray;

		.a-nav-text {
			@apply pl-20;
			@apply opacity-100;
		}
	}

	&__wrapper {
		@apply justify-between h-full;
		@apply content-center;
	}
}

.a-profile-item {
	@apply flex items-center;
	@apply px-4 py-4;

	&__header {
		@apply h-14;
		@apply rounded-large;
		@apply bg-darkGray;
	}

	&__icon {
		@apply w-5 h-5;
		@apply mr-4;
		@apply opacity-50;
	}

	&__label {
		@apply text-regentGray;
	}
}

.m-profile {
	@apply absolute;
	@apply left-70 bottom-32;

	@apply hidden;
	@apply min-w-60;

	@apply bg-white;
	@apply rounded-large;
	@apply shadow-lg;

	&:after {
		content: '';
		position: absolute;
		left: rem(-12.5px);
		bottom: rem(15px);

		border-top: rem(12.5px) solid transparent;
		border-right: rem(12.5px) solid #FFFFFF;
		border-left: none;
		border-bottom: rem(12.5px) solid transparent;
	}

	.a-profile-item {
		@apply border-b border-gray;

		&:last-child,
		&:first-child {
			@apply border-0;
		}
	}

}

.a-profile-link:hover>.m-profile {
	@apply block;
}
