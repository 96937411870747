@import '../../styles/base/functions.scss';

.o-accordeon {
	@apply flex flex-col;
	@apply w-full;
	min-height: rem(70px);
	@apply p-6 mb-6;
	@apply rounded-large;
	@apply cursor-pointer;

	@apply bg-white;
	@apply shadow-md;
}

.m-accordeon-header {
	@apply flex items-center;
	min-height: rem(26px);
}

.m-accordeon-children {
	@apply flex;
	@apply h-0;
	@apply overflow-hidden;

	&--open {
		@apply h-full;
	}
}

.a-close-button {
	transition: .25s transform ease-out;

	&--open {
		transform: rotate(90deg);
	}
}
