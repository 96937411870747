.m-toggle-filter__wrapper {
	position: relative;

	display: inline-block;
}

.toggle-filter {
	@apply relative;
	@apply inline align-middle;
	@apply font-medium leading-tight text-sm;
	@apply cursor-pointer opacity-75;

	svg {
		@apply inline-block align-middle ml-2;

		path {
			@apply fill-fiord;
		}
	}
}

.toggle-filter__dropdown {
	@apply absolute top-25 right-0 z-5;
	@apply flex-1 hidden;
	@apply shadow-lg rounded;
	@apply bg-white;

	width: rem(170px);

	.toggle-filter__dropdown__item {
		@apply border-b border-gray;

		&--active {
			@apply bg-gray text-blue;
		}

		&:last-child {
			@apply border-0;
		}

		&:hover {
			@apply bg-gray text-blue;
		}

		.toggle-filter__dropdown__item__button {
			@apply px-6 py-3;
			@apply inline-block w-full;
			@apply text-left text-sm font-light opacity-75;

			&:hover {
				@apply text-blue;
			}
		}
	}
}

.m-toggle-filter__wrapper:hover>.toggle-filter__dropdown {
	@apply block;
}
