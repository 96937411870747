.m-map {
	min-height: rem(200px);

	&__corner-wrapper {
		@apply absolute;
		@apply z-30;

		&--top-right {
			@apply top-15 right-15;
		}

		&--top-left {
			@apply top-15 left-15;
		}

		&--bottom-right {
			@apply bottom-25 right-15;
		}

		&--bottom-left {
			@apply bottom-15 left-15;
		}
	}

	&--has-zoom-controls {
		.m-map__corner-wrapper {
			&--top-right {
				@apply right-55;
			}
		}
	}
}
