@import '../../../core/styles/base/functions.scss';

.o-activity-log-list {
	@apply flex-auto overflow-y-auto;
	@apply w-full min-h-0 pt-8 pb-2;

	&--small {
		height: rem(368px);
	}
}
