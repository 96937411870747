.a-base-layout {
	@apply flex flex-row;
	@apply min-h-screen pl-20;

	@apply bg-darkGray;
	@apply font-sans;

	@screen desktop {
		@apply justify-center;
	}
}
