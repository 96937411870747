.o-modal__footer {
	@apply flex justify-center py-6;
	@apply bg-darkGray border-t border-gray;
}

.o-modal__close {
	@apply flex mt-2;
	@apply cursor-pointer;
}

.o-modal__header {
	@apply flex justify-between p-10 px-8 py-5 border-b;
	@apply border-gray;

	h3 {
		@apply pb-2;
		@apply text-lg font-medium leading-normal;
	}

	.subtitle {
		@apply inline-block mr-2;
		@apply text-xs leading-none opacity-50;
	}
}
