.u-grid-container {
	@apply flex flex-col;

	&--centered {
		@apply items-start;
		@apply w-full py-6 px-12 pt-12;

		@screen desktop {
			@apply px-12;
			@apply max-w-desktop-xl;
		}
	}
}
