@import '../../styles/base/functions.scss';
@import '../../styles/base/variables.scss';


.m-radio-button-group {
	.a-radio-button {
		&:first-child {
			label {
				@apply rounded-tl rounded-bl;
			}
		}

		&:last-child {
			label {
				@apply rounded-tr rounded-br;
			}
		}
	}
}
