@import '../../../core/styles/base/functions.scss';

.o-party-list {
	@apply w-full;

	@screen desktop {
		display: grid;
		grid-template-columns: calc(50% - #{rem(16px)}) calc(50% - #{rem(16px)});
		grid-gap: 2rem;
	}
}
