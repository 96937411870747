.a-search-bar {
	@apply relative;
	@apply inline-block;
	@apply text-base;

	&__input {
		@apply px-4 py-3 pr-12;
		@apply shadow-md rounded-lg;
		@apply outline-none;
		@apply w-full;
	}

	&__icon {
		@apply absolute top-15 right-15;
		@apply cursor-pointer;
	}
}
