@import '../../styles/base/functions.scss';
@import '../../styles/base/variables.scss';

.m-time-overview {
	@apply flex;
	@apply relative;

	&:before {
		content: '';
		background-size: rem(16px) rem(16px);
		background-position: center;
		background-repeat: no-repeat;
		@apply flex justify-center;
		@apply z-10;
		@apply w-6 h-6;
		@apply mr-5;
		@apply bg-white;
		@apply rounded-full;
	}

	&--first {
		@apply mb-4;

		&:after {
			content: '';
			background-image: url('../../../../assets/images/dotted-line.svg');
			@apply absolute;
			@apply bg-white;
			@apply rounded-full;
			@apply w-6 pt-3;
			background-origin: content-box;
			background-clip: content-box;
			background-repeat: repeat-y;
			background-position: center;
			// This height is 100% of the container + the margin between + the height of the 2 clock icons
			height: calc(100% + #{rem(40px)});
		}
	}
}

.m-time-overview__wrapper {
	&:first-of-type {
		@apply mb-8;
	}

	&--transparent {
		.m-time-overview {
			&::before {
				background-color: transparent;
			}

			&--first {
				&:after {
					padding-top: rem($time-overview-background-offset);
					background-color: transparent;
					// subtract padding top to stop black dots from showing up
					// behind the clocks
					height: calc(100% + #{rem(40px - $time-overview-background-offset)});
				}
			}
		}
	}
}

.a-activity-date-time {
	@apply flex flex-col;
	@apply w-19%;

	@screen desktop {
		@apply w-15%;
	}
}

.a-activity-time-label {
	@apply text-xs uppercase text-regentGray font-medium;

	&--active {
		@apply text-white;
		@apply opacity-80;
	}
}

.a-activity-date {
	@apply text-xs uppercase text-fiord;

	&--active {
		@apply text-white;
	}
}

.a-activity-time-wrapper {
	@apply flex flex-col justify-center;

	@screen desktop {
		@apply flex-row justify-between;
	}
}

.a-activity-time {
	@apply text-lg text-fiord;
	@apply mr-2;

	&--active {
		@apply text-white;
	}
}

.a-time-container {
	@apply flex-1;
}

.m-time-overview[data-color='gray'] {
	&:before {
		background-image: url('../../../../assets/images/clock--gray.svg');
	}
}

.m-time-overview[data-color='green'] {
	&:before {
		background-image: url('../../../../assets/images/clock--green.svg');
	}
}

.m-time-overview[data-color='orange'] {
	&:before {
		background-image: url('../../../../assets/images/clock--orange.svg');
	}
}

.m-time-overview[data-color='red'] {
	&:before {
		background-image: url('../../../../assets/images/clock--red.svg');
	}
}
