@import '../../../../core/styles/base/variables.scss';

.m-activity-list-item {
	@apply flex;
	@apply h-19;
	@apply px-8;
	@apply border-l-4 border-r border-t;
	@apply cursor-pointer;

	@apply bg-white;

	// tailwind overrides all colors,
	border-bottom-color: $border-color-blue !important;
	border-right-color: $border-color-blue !important;
	border-top-color: $border-color-blue !important;
	border-left-color: $border-color-blue;

	&--blue {
		@apply border-blue;
	}

	&--red {
		@apply border-red;
	}

	&--yellow {
		@apply border-yellow;
	}

	&--green {
		@apply border-green;
	}

	&--orange {
		@apply border-orange;
	}

	&:last-child {
		@apply border-b;
	}
}

.a-activity-list-content {
	@apply w-full;
	@apply flex items-center;
}

.a-activity-info {
	@apply pr-4;
	@apply text-fiord;
}

.a-activity-type {
	@apply w-1/4;
	@apply flex items-center;
	@apply opacity-70;

	span {
		@apply pl-4;
	}
}

.a-activity-date-time {
	@apply flex flex-col;
	@apply w-19%;

	@screen desktop {
		@apply w-15%;
	}
}

.a-activity-date {
	@apply text-xs uppercase;
	@apply opacity-50;
}

.a-activity-time-wrapper {
	@apply flex items-center;
}

.a-activity-time {
	@apply text-lg;
	@apply opacity-80;
	@apply mr-2;
}

.a-activity-alert {
	@apply flex justify-center flex-1;

	&-icon {
		@apply w-5;
	}
}

.a-activity-agent-info {
	@apply flex items-center;
	flex: 2;
	@apply pr-0;

	span {
		@apply flex-1;
		@apply pr-5;
	}
}
