@import '../../../../core/styles/base/functions.scss';

.m-party-list-item {
	@apply flex flex-col;
	@apply p-8 mb-8;
	@apply rounded-large shadow-md;
	@apply bg-white;

	&:last-of-type {
		@apply mb-0;
	}

	@screen desktop {
		@apply mb-0;
	}
}

.m-party-item-header {
	@apply flex;

	&::before {
		content: '';
		margin-left: rem(-16px);
		margin-right: rem(16px);
		@apply border-l-2 border-gray;
	}

	&--blue {
		&:before {
			@apply border-blue;
		}
	}

	&--green {
		&:before {
			@apply border-green;
		}
	}

	&--orange {
		&:before {
			@apply border-orange;
		}
	}

	&--red {
		&:before {
			@apply border-red;
		}
	}
}

.a-party-role {
	@apply uppercase;
	@apply text-xs text-regentGray;
}

.a-party-title {
	@apply text-lg text-cadet uppercase leading-tight;
}

.a-party-address,
.a-party-telephone,
.a-party-mail {
	@apply flex;
	@apply text-base font-normal text-cadet;
}

.a-party-address {
	@apply flex flex-col;
	@apply align-top;
}

.a-party-icon {
	@apply inline-block mr-6;

	path {
		@apply fill-cadet
	}

	width: rem(13px);
	height: rem(15px);
}
