@import '../../../styles/base/functions.scss';
@import '../../../styles/base/variables.scss';

.a-timeline-group {
	@apply w-full;

	&__content {
		@apply w-full h-full;
		@apply flex items-center;
		@apply p-2 pl-6;

		min-height: rem(60px);
	}

	&__name {
		@apply block;
		@apply text-base capitalize font-medium text-fiord;
	}
}
