.m-delay-label {
	@apply inline-flex;

	&--inner {
		@apply flex items-center;
		@apply px-2;
		@apply rounded-full;
		@apply text-white;

		.a-delay-icon path {
			@apply fill-white;
		}

		.a-delay-label-text {
			@apply text-xs;
			@apply pl-1;
		}

		&__green {
			@apply bg-gradient-tl-greenDarkGreen;
		}

		&__orange {
			@apply bg-gradient-tl-orangeDarkOrange;
		}

		&__red {
			@apply bg-gradient-tl-redDarkRed;
		}
	}
}
