@import '../../../core/styles/base/functions.scss';

.m-voyage-map {
	@apply w-3/4;
	height: rem(275px);
}

.m-map-legend {
	@apply flex flex-col justify-end flex-1;
	@apply  ml-10;

	&--item {
		@apply flex;
		@apply text-regentGray;

		&:first-child {
			@apply mb-3;
		}

		&__red-arrow {
			&:before {
				width: rem(25px);
				height: rem(25px);
				@apply mr-3;

				content: '';
				background-image: url('../../../../assets/markers/arrow-red.svg');
				@apply bg-center bg-contain bg-no-repeat;
			}
		}

		&__red-dot {
			&:before {
				width: rem(25px);
				height: rem(25px);
				@apply mr-3;

				content: '';
				background-image: url('../../../../assets/markers/red-dot.svg');
				background-size: rem(16px);
				@apply bg-center bg-no-repeat;
			}
		}
	}
}
