.m-activity-clock {
	@apply inline-flex;

	.a-activity-clock__icon {
		@apply mr-2;

		width: 15px;
		height: 20px;

		&--gray path {
			@apply fill-regentGray;
		}

		&--green path {
			@apply fill-darkGreen;
		}

		&--orange path {
			@apply fill-darkOrange;
		}

		&--red path {
			@apply fill-darkRed;
		}
	}

	.a-activity__time {
		@apply ml-1;
	}
}
