/* Typography */
$font-size: 16px;

/* Colors */
$text-blue: #506775;
$border-color-blue: #e6ecf0;
$fiord: #06273A;

$blackTransparent: rgba(0, 0, 0, 0.1);

$blue: #03A4EB;
$darkBlue: #0081BD;

$green: #ADC82E;
$darkGreen: #91A745;

// Orange
$orange: #EF7713;
$darkOrange: #B46C2F;

// Red
$red: #E31F26; // activity red
$darkRed: #A73030;

// Yellow
$yellow: #F8CA09; // activity yellow
$darkYellow: #C2AA3B;

/* Units */
$vis-custom-time-top-offset: 82px;
$time-overview-background-offset: 28px;
