@import '../../styles/base/functions.scss';

.a-toggle {
	@apply relative;
	@apply inline-block ml-2;
	@apply align-middle;

	height: rem(22px);
	width: rem(36px);

	&__slider {
		@apply absolute inset-0;
		@apply bg-regentGray cursor-pointer;

		transition: .4s;

		&:before {
			@apply absolute left-4 bottom-4;
			@apply h-3.5 w-3.5;
			@apply bg-white;

			content: '';
			transition: .4s;
		}

		&--round {
			@apply rounded-full;

			&:before {
				@apply rounded-full;
			}
		}
	}

	&__input {
		@apply hidden;

		&:checked+.a-toggle__slider {
			@apply bg-gradient-tl-blueDarkBlue;

			&:before {
				transform: translateX(14px);
			}
		}
	}
}
