.m-activity-header {
	@apply flex items-center;
	@apply mb-8;

	@screen desktop {
		@apply mb-10;
	}
}

.a-activity-label {
	@apply flex flex-col;
	@apply ml-6;

	&__title {
		@apply text-xs font-medium text-regentGray;
		@apply tracking-wide uppercase;
	}

	&__subtitle {
		@apply text-lg text-fiord;
	}
}

.a-activity-icon {
	path {
		@apply fill-regentGray;
	}
}

.m-time-overview-container {
	@apply w-1/2 pr-12;
	@apply border-r border-gray border-solid;
}

.a-list-item__title {
	@apply w-1/2;
	@apply pr-10 pb-4;
	@apply text-xs uppercase;
	@apply align-top;
	@apply text-regentGray;
}

.a-list-item__desc {
	@apply pb-4;
	@apply align-top;
	@apply text-fiord leading-none;
	@apply break-words;
}
