@import '../../styles/base/functions.scss';
@import '../../styles/base/variables.scss';


.a-radio-button {
	@apply relative inline-block;

	&__label {
		@apply inline-block px-4 py-1;
		@apply border border-electricBlue;
		@apply text-electricBlue text-center;

		min-width: rem(110px);

		&:hover {
			@apply cursor-pointer;
		}
	}

	&__icon {
		@apply inline-block mr-2;

		path {
			@apply fill-current;
		}
	}

	&__content {
		@apply flex items-center justify-center;
	}

	&__input {
		@apply absolute invisible;

		&:checked {
			&+.a-radio-button__label {
				@apply text-white bg-electricBlue;
			}
		}
	}
}
