@import '../../../../styles/base/variables.scss';
@import '../../../../styles/base/functions.scss';

.a-cluster-marker {
	@apply flex items-center justify-center;
	@apply border-4 rounded-full;

	@apply font-sans font-medium;
	@apply bg-white;

	padding: rem(2px);
	border-color: $border-color-blue;

	&--red {
		border-color: $red;
		color: $red;
	}

	&--blue {
		border-color: $blue;
		color: $blue;
	}
}
