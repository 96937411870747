@import '../../styles/base/functions.scss';

.m-activity {
	@apply flex items-center;
	@apply mb-8;

	&--no-margin {
		@apply mb-0;
	}

	&-label {
		@apply flex flex-col;
		@apply ml-6;

		&__title {
			@apply text-xs font-medium text-regentGray;
			@apply tracking-wide uppercase;

			&--active {
				@apply text-white;
				@apply opacity-80;
			}
		}

		&__subtitle {
			@apply text-lg text-fiord;

			&--active {
				@apply text-white;
			}
		}
	}

	&__icon {
		path {
			@apply fill-regentGray;
		}

		&--active {
			path {
				@apply fill-white;
			}
		}
	}

	@screen desktop {
		@apply mb-10;

		&--no-margin {
			@apply mb-0;
		}
	}
}
