@import '../../../core/styles/base/functions.scss';

.m-info-container {
	@apply flex flex-row justify-between;
	@apply w-full;
}

.o-activity-log-and-parties {
	@apply flex flex-row;
	@apply w-full;

	.o-activity-log {
		min-width: rem(360px);

		>article {
			@apply flex flex-col;
			@apply ml-0;
		}
	}

	.o-parties {
		@apply flex flex-col justify-start;
		@apply ml-8 w-full;
	}
}

.a-active {
	@apply bg-blue;
}

.m-activity-log__header {
	@apply flex justify-between;
	@apply mb-6;
	@apply text-fiord;

	.a-title {
		@apply inline-block mr-4;
		@apply font-medium text-lg;
	}

	.a-badge {
		@apply mr-4;
	}
}

.m-activity-log__actions {
	@apply px-8 py-6;
	@apply border-t border-gray;
}

.m-parties__header {
	margin-bottom: rem(25.6px);

	>.a-title {
		@apply inline-block align-middle;
	}
}
