@import '../../../../core/styles/base/variables.scss';

.m-map-message {
	@apply absolute top-0 right-0 bottom-0 left-0 z-40;
	@apply flex flex-col items-center justify-center;
	background-color: rgba($fiord, 0.3);

	&__content {
		@apply top-1/2 left-1/2;
		@apply flex flex-col items-center justify-center;
		@apply p-4;
		@apply bg-white rounded-lg;
	}
}
