.o-modal {
	@apply z-50;
	@apply fixed top-0 left-0;
	@apply flex w-full h-full;
	@apply items-center justify-center;
	@apply font-sans;

	&__overlay {
		@apply absolute z-40;
		@apply w-full h-full;
		@apply bg-fiord opacity-90;
	}

	&__wrapper {
		@apply z-50;
		@apply flex flex-col w-75vw max-h-90vh;
		@apply bg-white rounded-lg shadow-lg overflow-hidden;

		@screen desktop {
			@apply w-1/2;
		}
	}

	&__body {
		@apply h-full m-8 overflow-y-auto;
	}

	&--borderless {
		.o-modal__body {
			@apply m-0;
		}
	}

	&--small {
		.o-modal__wrapper {
			@apply max-w-lg;
		}
	}
}
