@import '../../styles/base/variables.scss';
@import '../../styles/base/functions.scss';

.a-button {
	@apply px-4 py-2;
	@apply rounded text-base font-medium leading-normal;

	&:focus {
		@apply outline-none;
	}

	&__content {
		@apply flex justify-center items-center;
	}

	&__icon {
		@apply mr-2;

		svg {
			width : rem(14px);
			height: rem(14px);

			path {
				@apply fill-fiord;
			}
		}
	}

	/** Button types */
	&--primary {
		@apply bg-blue text-white;

		.a-button__icon {
			svg path {
				@apply fill-white;
			}
		}
	}

	&--secondary {
		@apply border bg-white border-gray text-lightBlue;

		.a-button__icon {
			svg path {
				@apply fill-lightBlue;
			}
		}
	}

	&--basic {
		@apply bg-transparent text-fiord;
	}

	&--default {
		@apply bg-white text-fiord;
	}

	&--tab-primary {
		@apply px-5;
		@apply bg-blue text-white leading-tight;

		.a-button__icon {
			svg path {
				@apply fill-white;
			}
		}
	}

	&--tab-primary-outline {
		@apply border bg-white border-gray text-sanJuan leading-tight;

		.a-button__icon {
			svg path {
				@apply fill-sanJuan;
			}
		}
	}

	/** Button states */
	&--rounded {
		@apply rounded-full leading-tight;
		min-width: rem(80px);
	}

	/** Button sizes */
	&--small {
		@apply px-3 py-2 text-sm leading-tight;
	}

	&--big {
		@apply px-6 py-4;
	}

	/** Button disabled */
	&--disabled {
		@apply opacity-75;
		@apply cursor-not-allowed;
	}

	/** Font weight */
	&--font-bold {
		@apply font-bold;
	}

	&--font-light {
		@apply font-light;
	}
}
