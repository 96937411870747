.a-timeline-group {
	@apply w-full;

	&__content {
		@apply block w-full h-full;
		@apply p-2;
	}

	&__role {
		@apply block;
		@apply text-xs uppercase font-bold text-regentGray;
	}

	&__name {
		@apply block;
		@apply text-base capitalize font-medium text-fiord;
	}
}
