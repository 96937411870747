.m-info-column {
	@apply flex flex-1;
	@apply mt-6;

	&:first-child {
		@apply mt-0;
	}
}

.a-info-column-item {
	@apply w-1/2;

	&--left {
		@apply pr-12;
		@apply border-r border-gray border-solid;
	}

	&--right {
		@apply pl-12;
	}
}


