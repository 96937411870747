@import '../../../core/styles/base/functions.scss';

.m-infoBox {
	@apply flex-1;
	@apply mx-4;
	@apply rounded-large;

	@apply bg-white;
	@apply shadow-md;

	&--active {
		@apply bg-gradient-tl-blueDarkBlue;
	}

	&--full-width {
		@apply w-full;
	}

	&:first-of-type {
		@apply mx-0 mr-4;
	}

	&:last-child {
		@apply mx-0 ml-4;
	}

	@screen desktop {
		max-width: rem(375px);

		&--full-width {
			max-width: 100%;
			@apply w-full;
		}
	}
}
