@import '../../styles/base/functions.scss';
@import '../../styles/base/variables.scss';

@import '~react-table/react-table.css';

.ReactTable {
	@apply border-0;

	.rt-th {
		@apply text-sm font-light text-xs;
	}

	.rt-th__sortable {
		@apply inline-block ml-2;
		@apply mt-negative-1;
		@apply cursor-pointer;

		width: 7px;
		height: 7px;
	}

	.rt-td {
		@apply text-sm font-normal;
		color: $text-blue;

		span {
			@apply text-sm;
		}
	}

	.rt-tr {
		@apply px-10;
	}

	.rt-noData {
		display: none;
	}

	.rt-tbody {
		.rt-tr {
			@apply cursor-pointer;
		}

		.rt-td {
			@apply flex items-center w-full;
			border-right: none;

			min-height: 5rem;
			
			&>span {
				@apply min-w-0 truncate;
			}
		}

		.rt-tr-group {
			@apply border-b border-solid;
			border-color: $border-color-blue;
		}
	}

	&.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
		@apply bg-gray;
	}

	.rt-thead {
		@apply p-0 font-bold;

		&.-header {
			box-shadow: none;
			border-bottom: #{rem(2px)} solid $border-color-blue;
		}

		.rt-resizable-header-content {
			@apply text-xs;
		}

		.rt-th {
			@apply py-4 uppercase text-left;

			border-right: none;
			color: $text-blue;

			&.text-center {
				text-align: center !important;
			}
		}
	}

	.a-remarks-row {
		max-width: rem(80px);
	}
}
