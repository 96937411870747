.a-alert-type {
	@apply text-fiord text-lg;
}

.a-alert-message {
	@apply pl-10;
	@apply text-regentGray text-lg;
}

.a-list-item__title {
	@apply w-1/2;
	@apply pr-10 pb-4;
	@apply text-xs uppercase;
	@apply align-top;
	@apply text-regentGray;
}

.a-list-item__desc {
	@apply pb-4;
	@apply align-top;
	@apply text-fiord leading-none;
	@apply break-words;
}
