@font-face {
	font-family: 'Circular';
	src:
		url('../../../../assets/fonts/Circular/CircularStd-Medium.woff2') format('woff2'),
		url('../../../../assets/fonts/Circular/CircularStd-Medium.woff') format('woff'),
		url('../../../../assets/fonts/Circular/CircularStd-Medium.otf') format('opentype');
	font-style: normal;
	font-weight: 500;
}

@font-face {
	font-family: 'Circular';
	src:
		url('../../../../assets/fonts/Circular/CircularStd-Book.woff2') format('woff2'),
		url('../../../../assets/fonts/Circular/CircularStd-Book.woff') format('woff'),
		url('../../../../assets/fonts/Circular/CircularStd-Book.otf') format('opentype');
	font-style: normal;
	font-weight: 400;
}

body {
	@apply text-base subpixel-antialiased;
}

h1,
h2,
h3,
b {
	@apply font-bold;
}

h1 {
	@apply text-2xl;
}

h2 {
	@apply text-xl;
}

h3 {
	@apply text-lg;
}

h4 {
	@apply font-medium text-base;
}
