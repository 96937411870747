.m-activity-log-list-item {
	@apply mr-8;
	@apply text-fiord font-light;

	.a-log-item__is-read {
		@apply rounded-full;

		height: 0.4rem;
		width: 0.4rem;
		margin-top: 7px;
		margin-left: 0.875rem;
		margin-right: 0.875rem;
		flex-shrink: 0;

		&--white {
			@apply bg-white;
		}

		&--red {
			@apply bg-red;
		}
	}

	.a-log-item__type {
		svg {
			@apply h-5 w-5;

			path {
				@apply fill-regentGray;
			}
		}
	}

	.m-log-item__content {
		@apply flex pb-5 mb-5;
		@apply w-full;
		@apply border-b border-gray;

		.content__inner {
			@apply flex flex-col;
			@apply w-full;
			@apply ml-4;
		}

		.content__title {
			@apply mb-2;
			@apply w-90%;
			@apply leading-snug;

			font-size: 15px;

			&--new {
				@apply font-bold;
			}
		}

		.content__created-on {
			@apply text-xs font-medium;
			@apply text-fiord opacity-50;
		}

		@screen desktop {
			.content__inner {
				@apply flex justify-between flex-row;
				@apply w-full;
				@apply ml-4;

				&--small {
					@apply flex-col;
				}
			}

			.content__created-on {
				@apply ml-6;

				&--small {
					@apply ml-0;
				}
			}
		}
	}

	&:last-child {
		.m-log-item__content {
			@apply border-0;
		}
	}
}
