.a-badge {
	@apply inline-block px-1 rounded-sm;
	@apply bg-darkGray text-white text-sm font-light leading-tight;

	transition: background-color 200ms ease-in;

	&--red {
		@apply bg-red;
	}

	&--green {
		@apply bg-green;
	}

	&--blue {
		@apply bg-blue;
	}

	&-icon {
		@apply inline-block ml-1;

		margin-top: -4px;
		width: 10px;
		height: 12px;

		path {
			@apply fill-white;
		}
	}
}
